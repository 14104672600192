/* globals $, console, getProgramWarehouseType, scanLocation, countTrailerCasks, pickingAllocation, getProgramName, hasProgramParameter, getDisgorgeLine,
loadAllocationDetailPopup, generateChangeTrailerPopup, getBarcodeMapping, sessionValidate, attachFixedFocus, attachInputEvents, beginPollingForAllocationChanges,
updatePickingAllocation, createEnterAction, playSuccessSound, isSessionValid, getLabels, getProgramTransactionCode, limitString, saveGenericTransaction,
 VesselBarcodeType */

/**
 * Scanning functions.
 * The functions here are designed to be used in several screens where generic scanning takes place.
 * Scanning input UI should be shown from template files. These are then rendered here.
 * When scans take place, scan processing here checks and validates input before moving on.
 * Display of the scan details is then required to update the UI of counters / last scans.
 * Template file: ds.cask-scan.template.html. All elements modified in here are in that template.
 */

var scanningBehaviour = {
	showLocationInformation :true,
	showAllocationInformation: false,
	validateAgainstAllocation: false,
	usingScotchWhiskyData: Boolean(localStorage.getItem("settings/useSWA") === "true"), // Global replacement of useSWA.
	scanningStarted: false,
	displayFields: {
		scotchLabel: "Cask",
		caskIdLabel: "Cask ID",
		showBatch: false,
		showMake: false,
		showFillYear: false,
		showRotation: false,
		showCaskNumber: false
	}
};

/**
 * @description Set default options for a movement program scanning into a location.
 */
function setStandardLocationScanning() {
	"use strict";
	// Most value defaults are already correct.
	scanningBehaviour.showLocationInformation = true;
}

/**
 * @description Set options for the default drop program, where no location information is needed.
 */
function setDropScanning() {
	"use strict";
	// Everything should be hidden.
	scanningBehaviour.showLocationInformation = false;
}

/**
 * @description Set options for scanning in Trailer Load but not against an allocation.
 */
function setTrailerLoadingWithoutAllocation() {
  "use strict";
	scanningBehaviour.showLocationInformation = false;
}

/**
 * @description Set options to scan against an allocation AND show location information.
 */
function setShowAndValidateAgainstAllocation() {
	"use strict";
	scanningBehaviour.showAllocationInformation = true;
	scanningBehaviour.validateAgainstAllocation  = true;
}

/**
 * @description Set options to show standard allocation scanning, without any location information.
 */
function setStandardAllocationScanning() {
	"use strict";
	scanningBehaviour.showAllocationInformation = true;
	scanningBehaviour.validateAgainstAllocation  = true;
	scanningBehaviour.showLocationInformation = false;
}

/**
 * @description Generic function for use with scanInput templates for showing manual SWA entry.
 * @returns {Boolean} True if the manual button is being shown.
 */
function checkAndShowManualBarcodeEntry() {
	"use strict";
	// Get the barcode types allowed in the program
	var barcodesUsableInProgram = getProgramVesselBarcodeType();
	// Check SWA is an option or the program scans all.
	if (barcodesUsableInProgram ==="ALL" || barcodesUsableInProgram.search(VesselBarcodeType.scotch) >= 0 ) {
		// Resize the scan field and show the enter manually button.
		$("#scanInputDiv").removeClass('col-xs-12').addClass('col-xs-9');
		$("#manualButtonDiv").show();
		return true;
	}
	return false;
}

/**
 * @descriptions Shows scanning elements to begin scanning once Location or Allocation information is entered.
 * Uses scanningBehaviour global to determine what is to be displayed.
 */
function showScanningElements() {
  "use strict";

	var labels = getLabels();
	// also add code to decide if the manual SWA input should be show. That should change css for the input field too.
	$("#programContainer").load('templates/ds.cask-scan.template.html', function() {
		console.log("Template Loaded");

		// Ensure the scan label is updated correctly.
		$("#scanInputLabel").html('Scan ' + labels.caskLabel + ':');
		// Decide if the manual SWA button is shown based on the programs capability.
		checkAndShowManualBarcodeEntry();

		// Check and see if we should show location details
    if(scanningBehaviour.showLocationInformation) {
			// Show the table and fields for scanningLocation information
			var warehouseType = getProgramWarehouseType(),
				labelType;
			if (warehouseType === "RACK") {
				labelType = "racked";
			} else if (warehouseType === "PALL") {
				labelType = "pallet";
			} else {
				labelType = "racked"; // Defaults to rack labels which are best for Dunnage or unknown.
			}
			$('.locationDetailSection').show();

			if(scanLocation.warehouse !== "") {
				$('.scannedWarehouse').show();
				$('#scannedWarehouse').html(scanLocation.warehouse);
			}

			if(scanLocation.bay !== "") {
				$('.scannedBay').html(labels[labelType].bay).show();
				$('#scannedBay').html(scanLocation.bay);
			}

			// This is set if R warehouse and transaction overrides the bay with the rack value.
			// Could update bay label to show bay/rack
			if(scanLocation.rack !== "" && !scanLocation.rackAndBayAreTheSame) {
				$('.scannedRack').html(labels[labelType].rack).show();
				$('#scannedRack').html(scanLocation.rack);
			}
			if(scanLocation.level !== "") {
				$('.scannedLevel').html(labels[labelType].level).show();
				$('#scannedLevel').html(scanLocation.level);
			}
			if(scanLocation.direction !== "") {
				$('.scannedDirection').show();
				$('#scannedDirection').html(scanLocation.direction);
			}

			if(localStorage.getItem("scanTrailer") && !hasProgramParameter("TRAILER")) {
				// This only shows when we didn't provide TRAILER as an input. I.E. stow from trailer.
				// TRAILER is provided in the loading, scanning against an allocation. We would show the trailer state in allocationTrailer.
				// This UI element is shown when we scan out of the trailer.
				var unloadingTrailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
				localStorage.setVariable("scanTrailerContent", unloadingTrailerInfo.content, true);
				$('.scannedTrailer').show();
				$('#scannedTrailer').html(localStorage.getVariable("scanTrailer") + ' 0/' + unloadingTrailerInfo.content);
			}

			// As we are scanning against a location, then show the change button, but only when not also showing allocations
			if(!scanningBehaviour.showAllocationInformation) {
				$('.locationButtonSection').show();
			}
		}

		//Check and see if we aren't showing anything. This could occur in DROPS where no fields are displayed.
		if (!scanningBehaviour.showLocationInformation && !scanningBehaviour.showAllocationInformation) {
			$('.locationButtonSection').show(); //Show the button area to complete the scanning operations
			$('#changeLocationButton').html("Complete");
		}

		//Check and see if we should show allocation details
		if(scanningBehaviour.showAllocationInformation) {
			$('.allocationSection').show(); // Show the section that will have the allocation info.
			//Check what type of allocation it is. This will modify data shown and labels on screen.
			//Cached elements:
			var allocationNumber = $('#allocationNumber'),
				allocationVersion = $('#allocationVersion'),
				allocationInformationHeader = $("#allocationInformationHeader"),
				allocationLookupButtonSmall = $("#allocationLookupButtonSmall"),
				allocationLookup = $("#allocationLookup");

			//Change based on the type of allocation below. Normal / default allocations do not change at all.
			switch(pickingAllocation.type) {
				case "RECEIPT":
					allocationInformationHeader.html("Receipt");
					allocationLookupButtonSmall.html("Check Receipt");
					allocationLookup.html("Check Receipt");
					allocationNumber.hide();
					allocationVersion.hide();
					break;
				case "DESPATCH":
					allocationInformationHeader.html("Despatch");
					allocationLookupButtonSmall.html("Check Despatch");
					allocationLookup.html("Check Despatch");
					break;
			}

			//Show the counts
			allocationNumber.html(pickingAllocation.number);
			allocationVersion.html(pickingAllocation.version);

			//calculate cask requirement
			var casksRequired = (pickingAllocation.hasOrderLines) ? countTotalRequiredCasks(pickingAllocation.orderLines, pickingAllocation.casks): pickingAllocation.casks.length;

			$('#allocationTotal').html(pickingAllocation.scanned +"/"+ casksRequired);
			// Add disgorging line if that's what we're doing.
			if(getProgramName().toLowerCase() === "disgorge" || hasProgramParameter("LINE")) {
				$('.allocationLine').show();
				$('#allocationLine').html(getDisgorgeLine());
			}

			//Show the appropriate buttons for checking allocations
			if(scanningBehaviour.showLocationInformation) {
				$('.mixButtonSection').show(); //Show both buttons for change location and check allocation - side by side
			} else {
				$('.allocationButtonSection').show(); //Only show the allocation check button
			}
		}

		// Check and see if the program has a trailer input. If so we show this for trailer loading only.
		if (hasProgramParameter("TRAILER")) {

      // If the allocation is already showing then we dont need to do anything extra.
			// If not we need to show the section, but hide the information column.
			// This is to display trailer information for the trailer load screen, but only when no allocation is entered.
			if (!scanningBehaviour.showAllocationInformation) {
				$("#allocationSection").show();
				$("#allocationInformation").hide();
			}

			var trailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
			$('.allocationTrailer').show();
			$('#allocationTrailer').html(localStorage.getItem("scanTrailer") + ' (' + trailerInfo.content + '/' + trailerInfo.capacity + ')');
			$('.changeTrailerButtonSection').show();
			$("#changeTrailerButton").on("click", function () {
				generateChangeTrailerPopup(function (validTrailer) {
					if (validTrailer) {
						// update ui
						var validTrailerInfo = countTrailerCasks(localStorage.getItem("scanTrailer"));
						$('#allocationTrailer').html(localStorage.getItem("scanTrailer") + ' (' + validTrailerInfo.content + '/' + validTrailerInfo.capacity + ')');
						$("#scanInput").focus();
					}
				});
			});
		}

		// Show the cask details table
		var barcodeMap = getBarcodeMapping();
		// Setup scanning variables to hold if the fields can be displayed based on the mappings.
		scanningBehaviour.displayFields.showBatch = barcodeMap.batch.length > 0;
		scanningBehaviour.displayFields.showMake = true;
		scanningBehaviour.displayFields.showFillYear = true;
		scanningBehaviour.displayFields.showRotation = true;
		scanningBehaviour.displayFields.showCaskNumber = true;
		scanningBehaviour.displayFields.scotchLabel = labels.caskLabel;
		scanningBehaviour.displayFields.caskIdLabel = labels.caskLabel + " ID";

		// If we will have useful information we will show it
		// This can be tricky if a program accepts more than one barcode type.
		// If it does then some of the fields might not populate. This would look messy.
		// If the program only accepts SWA then its easy and we show all fields
		// If the program accepts both or is only for Cask IDs we should show the 'Last Cask' label and column only.
		// If scanning against an allocation however, we can show more fields no matter which label is used.
		// To be backward compatible we hide the fillYear and rotation if only accepting Cask IDs.
		// I.E. If the program accepts both we can show those fields when scanning against an allocation.
    // The below code will initialise the table but the displayLastScanInfo will update the table based on what is scanned.
		var programBarcodes = getProgramVesselBarcodeType();
		var scotchOnly = programBarcodes === VesselBarcodeType.scotch; // Program must only accept SWA labels.
		var caskIdOnly = programBarcodes === VesselBarcodeType.caskId; // Program must only accept CID labels.
		if(scotchOnly || scanningBehaviour.validateAgainstAllocation) {
			if (scanningBehaviour.displayFields.showBatch) {
				$('.scannedBatch').show();
			}
			if (scanningBehaviour.displayFields.showMake) {
				$('.scannedMake').show();
			}
			// WARNING: the two below might display oddly if scanning pallets in allocation screen.
			// Likely these will appear initially but when a pallet is scanned they will disappear.
			// It might be better to change after && to be scotchOnly only.
			if (scanningBehaviour.displayFields.showFillYear > 0 && (scotchOnly || !caskIdOnly)) {
				$('.scannedYear').show();
			} // Only shown with full SWA scanning
			if (scanningBehaviour.displayFields.showRotation > 0 && (scotchOnly || !caskIdOnly)) {
				$('.scannedRotation').show();
			} // Only shown with full SWA scanning
			if (scanningBehaviour.displayFields.showCaskNumber) {
				$('.scannedCask').html(scanningBehaviour.displayFields.scotchLabel);
			}
		} else {
			// We may not have any SWA detail, and simply be scanning a caskId - so we just display the tag.
			if (caskIdOnly) {
				$('.scannedCask').html(scanningBehaviour.displayFields.caskIdLabel);
			} else {
				$('.scannedCask').html('Last Scan'); // Not an only type of label so show something generic.
			}
			// Original code for trailer movements allowed the trailer to be shown here in a second column.
			// This is now shown in the location table at the end next to level.
		}

		// Finally we handle scanner input capture
		createEnterAction("#scanInput", true, function(inputValue) {
			if(!isSessionValid()) {
				return;
			} // validate session before saving any data.
			// Create the barcode object and input to processing.
			var barcodeObject = createBarcodeObject(inputValue, "scanInput");
			processScanningEvent(barcodeObject, "Internal", "", function() {});
		});

		// Also handle the manual button press here.
			$('#manualScanButton').on('click', function() {
				loadManualCaskPopup(previous_scan, function(valid, manualBarcodeObject) {
					if (valid) {
						// If the manual details were valid, the barcode object from it is passed into process.
						processScanningEvent(manualBarcodeObject,"Internal","",function(success) {
							if (success) {
								// success. close the dialog as the barcode / transaction saved.
								$("#manualCaskPopup-modal").modal("hide");
							}
						});
					}
				});
			});

		attachFixedFocus("#scanInput");
		attachInputEvents();
		document.getElementById('scanInput').focus();

		beginPollingForAllocationChanges();
	});
}

/* globals createBarcodeObject, validateRackLimit, isCaskValidInAllocation, updateAllocationCounter, getBarcodeFromBarrelId, barcodeToData,
storeTransaction, sendTransactions, config, displayLastScanInfo, doesProgramSendTransactions, getCaskDataFromBarrelId, isLocationCapacityBreached */
/**
 * @description Process the barcode scanned. Replaces processScan in older syntax.
 * @param barcodeObject
 * @param {string} caller - Identifies function caller. If "Internal" then called from scan.js, and displays details
 * @param {string} eventCode - An event code to save against the scanning event.
 * @param callback - The function to run when completed processing
 */
function processScanningEvent(barcodeObject, caller, eventCode, callback) {
	"use strict";
	var allocationErrorState,
		validInAllocation = false;

	// Some standard checks. Ensure barcodeObject is not null and has passed.
	if(barcodeObject && !barcodeObject.passed) {
		callback(false);
		return false;
	}

	//Checking to see if scanning into a location and that it is not over capacity
  if(!validateRackLimit()) {
		callback(false);
		return false;
	}

	// Checking to see if the warehouse capacity has been breached for the scanLocation.
	if(isLocationCapacityBreached()) {
		callback(false);
		return false;
	}

	// Checking to see the cask is valid in the allocation if it should be checked.
	if(scanningBehaviour.validateAgainstAllocation) {
		// Ensure cask is in the allocation
		allocationErrorState = isCaskValidInAllocation(barcodeObject);
		if (allocationErrorState) {
			if (allocationErrorState.stopProgram) {
				return false;
			}
			validInAllocation = (allocationErrorState.name !=="notOnAllocation");
		} else {
			validInAllocation = true;
		}
	}

	//save transaction.
	saveGenericTransaction(barcodeObject, allocationErrorState, eventCode, function (success) {
		if (success) {
			//send if allowed
			if (doesProgramSendTransactions()) {
				sendTransactions(false);
			}

			if (!allocationErrorState) {
				playSuccessSound(true);
			}

			//output details to the screen, when applicable.
			//bit of an oddity but binds information to the appropriate screen.
			//Normal Internal updated generic fields
			//External-LastScan allows a scannedCask field to be updated automatically
			//External does nothing with displays, and the return value could do something.
			switch (caller) {
				case "Internal":
					displayLastScanInfo(barcodeObject, validInAllocation);
					break;
				case "External-LastScan":
					$('#scannedCask').html(barcodeObject.displayCode);
					break;
				case "External":
					break;
			}

			//final catch - if we have disgorged and have an error status, then now that the transaction has sent we return false
			//for the manual popup
			callback(!(allocationErrorState && getProgramTransactionCode().toUpperCase() === "DISGORGE"));
			return !(allocationErrorState && getProgramTransactionCode().toUpperCase() === "DISGORGE");
		} else {
			callback(false);
			return false; // for the manual popup
		}
	});
}

/* globals movement_casks, isTrailerFull, updateTrailerContent, showAlert, isCaskOnTrailer, deductTrailerContent, updateTemplateAllocationCounter */
/**
 * @description Displays the details of the last scan to the screen
 * @param barcodeObject
 * @param validInAllocation
 */
function displayLastScanInfo(barcodeObject, validInAllocation) {
  "use strict";
	// Disable the menu button and update the label for location changing
	if (!scanningBehaviour.scanningStarted && scanningBehaviour.showLocationInformation) {
		$('#exitbutton').attr("disabled", "disabled");
		$('#changeLocationButton').html('Complete Location');
		$('#changeLocationButtonSmall').html('Complete Location');
		scanningBehaviour.scanningStarted = true;
	}

	var defaultScanLabel = VesselBarcodeType.pallet ? 'Last Pallet' : 'Last Scan'; // Customise if we can in the default.
	// If we are displaying then we have already filtered out what barcode types are allowed. So display the relevant type.
	// This also means showing and hiding available fields to make the columns make better sense.
	switch (barcodeObject.vesselBarcodeType) {
		case VesselBarcodeType.scotch:
			// Check the fields are allowed to be displayed and show the information.
			if (scanningBehaviour.displayFields.showBatch) {
				$('.scannedBatch').show();
				$('#scannedBatch').html(barcodeObject.swa.batch);
			}
			if (scanningBehaviour.displayFields.showMake) {
				$('.scannedMake').show();
				$('#scannedMake').html(limitString(barcodeObject.swa.make,9));
			}
			if (scanningBehaviour.displayFields.showFillYear) {
				$('.scannedYear').show();
				$('#scannedYear').html(barcodeObject.swa.fillYear);
			}
			if (scanningBehaviour.displayFields.showRotation) {
				$('.scannedRotation').show();
				$('#scannedRotation').html(barcodeObject.swa.rotation);
			}
			if (scanningBehaviour.displayFields.showCaskNumber) {
				$('.scannedCask').show().html(scanningBehaviour.displayFields.scotchLabel); // make sure correct label.
				$('#scannedCask').html(barcodeObject.swa.caskNumber);
			}
			break;
		case VesselBarcodeType.caskId:
			if (scanningBehaviour.validateAgainstAllocation) {
				// We can show Batch and Make in addition to the cask ID.
				// Check the fields are allowed to be displayed and show the information.
				if (scanningBehaviour.displayFields.showBatch) {
					$('.scannedBatch').show();
					$('#scannedBatch').html(barcodeObject.swa.batch);
				}
				if (scanningBehaviour.displayFields.showMake) {
					$('.scannedMake').show();
					$('#scannedMake').html(limitString(barcodeObject.swa.make,9));
				}
				if (scanningBehaviour.displayFields.showCaskNumber) {
					$('.scannedCask').show().html(scanningBehaviour.displayFields.caskIdLabel); // Update the label
					$('#scannedCask').html(barcodeObject.displayCode); // This is actually the Cask ID not the SWA cask number.
				}
			} else {
				// Just show the cask ID.
				$('.scannedCask').show().html(scanningBehaviour.displayFields.caskIdLabel); // Update the label
				$('#scannedCask').html(barcodeObject.displayCode); // This is actually the Cask ID not the SWA cask number.
				// Also ensure fields that might have been displayed before are removed. This is the SWA specific ones.
				$('.scannedBatch').hide();
				$('.scannedMake').hide();
			}
			// Also ensure fields that might have been displayed before are removed. This is the SWA specific ones.
			$('.scannedYear').hide();
			$('.scannedRotation').hide();
			break;
		default:
			// Likely we are just displaying a pallet number. Other labels will fall here too if they are ever created.
			$('.scannedCask').show().html(defaultScanLabel); // Set as the generic label.
			$('#scannedCask').html(barcodeObject.displayCode);
			// Also ensure fields that might have been displayed before are removed. This is the SWA specific ones.
			$('.scannedBatch').hide();
			$('.scannedMake').hide();
			$('.scannedYear').hide();
			$('.scannedRotation').hide();
	}

	// Update the allocation count displayed, if the cask was indeed valid in the allocation
	if (validInAllocation) {
		updateTemplateAllocationCounter();
	}

	// Show trailer info where applicable
  if (hasProgramParameter("TRAILER")) {
		//This is an allocation program, likely trailer load
		if (isTrailerFull(localStorage.getItem("scanTrailer"))) {
			showAlert("The trailer Should be full.", {type: "info"});
		}
		var newTrailerInfo = updateTrailerContent(localStorage.getItem("scanTrailer"));
		if (newTrailerInfo.content === newTrailerInfo.capacity) {
			showAlert("Trailer should now be full.", {type: "info"});
		}
		$("#allocationTrailer").html(localStorage.getItem("scanTrailer") + ' (' + newTrailerInfo.content + '/' + newTrailerInfo.capacity + ')');
	} else if (localStorage.getItem("scanTrailer")) {
		// We don't have the TRAILER parameter but the scanTrailer is still set. We must be unloading the trailer.
		if (isCaskOnTrailer(localStorage.getItem("scanTrailer"), barcodeObject)) {
			var trailerInfo = deductTrailerContent(localStorage.getItem("scanTrailer"));
			$("#scannedTrailer").html(localStorage.getItem("scanTrailer") + ' ' + trailerInfo.content + '/' + trailerInfo.oldContent);
		}
	}

  //Finally update the Total transactions button with the latest count of transactions (if shown).
	$("#scansField").html(movement_casks.length);
}
